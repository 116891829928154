.cursor_pointer{
  cursor: pointer !important;
}

.imgProduct{
  max-height: 250px !important;
}


.logStore{
  max-height: 100px !important;
}

.imgProductPos{
  max-height: 100px !important;
  min-height: 100px !important;
}

.contentProducts{
  max-height: 700px !important;
  overflow: auto;
}

.contentProductsOnline{

  overflow: auto;
}


.boldText{
  font-weight: bold;
}

.row-flex {
  display: flex !important;
}
.row-flex .col {
  min-height: 100% !important;
}

.card {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}
.card-content {
  flex: 1 !important;
}


.sale_order:hover{
  background-color: #f5f5f5 !important;
}

.activeOption{
  padding-left: 14px;
  border-left: 2px solid #ee6e73;
  font-weight: bold !important;
  color: black;
}

.cardPosOnline{
  border: 1px solid;
  border-radius: 10px;
  padding-top: 1rem !important;
}

.cardPosOnline:hover{
  cursor: pointer !important;
  background-color: #8080801c;
}

#modalOrder{
  top: 0% !important;
  min-height: 100% !important;
  width: 100% !important;
}

.cardTypeOrder{
  border: 1px solid;
  border-radius: 10px;
  margin-top: 1rem;
  padding: 30px;
  font-size: 30px;
  cursor: pointer;
}

.cardTypeOrderRest{
  border: 1px solid;
  border-radius: 10px;
  margin-top: 1rem;
  padding: 30px;
  font-size: 30px;
  cursor: pointer;
}

.ft-50px{
  font-size: 50px !important;
}

.ft-40px{
  font-size: 40px !important;
}

.cardTypeOrder:hover{
  background-color: black !important;
  color: white !important;
}


.cardTypeOrder.active{
  background-color: black !important;
  color: white !important;
}

.successOrder{
  font-size: 100px;
  margin-top: 2rem;
  color: green;
}

.cardOrderOnline{
  border: 1px solid;
  border-radius: 10px;
}


/* Agregar este CSS personalizado */
.left-align-on-small {
  text-align: left;
}

/* Aplica en pantallas medianas o mayores (cuando el `div` es m9) */
@media only screen and (max-width: 600px) {
  .left-align-on-small {
    text-align: center; /* Cambia a center-align para pantallas medianas o más grandes */
  }
}

.prodIMG{
  max-height: 100px !important;
}


.ft10{
  font-size: 10px !important;
}

.ft12{
  font-size: 12px !important;
}

.mt-1r{
  margin-top: -1rem !important;
}

.mt-1{
  margin-top: 1rem !important;
}

.mt-4{
  margin-top: 4rem !important;
}

.txtJustify{
  text-align: justify !important;
}

.cursorPointer{
  cursor:  pointer !important;
}


/*ESTILOS POR MARCA*/

.primary{
  color: #6b1331 !important;

}


.secondary{
  color: black !important;
}

/*
.secondary{
  color: white !important;
}

.third{
  color: #36B0D3 !important;
}

.bgBrand{
  background-color: #302c45 !important;
}

.customImgBrand{
  border: solid !important;
  border-color: white !important;
  border-radius: 10px !important;
}

.cardPosOnlineBrand{
  border-color: white;
}
  */

  #botones a,
#botones i {
  pointer-events: auto;
}

.optMenu{
  padding: 1rem !important; 
  border-radius: 10px !important; 
  color: #6b1331 !important;
}

.optMenu:hover{
  padding: 1rem !important; 
  border-radius: 10px !important;
  color: #00bfa5 !important; 
}