.logoVino{
    max-height: 50px !important;
    margin-top: 4px !important;
    border-radius: 10px !important;
}

.nav-wrapper{
    background-color: #6b1331 !important;
}

.divider{
    background-color: #782020 !important;
}

.btn{
    background-color: #6b1331 !important;
    color: white !important;
}